import Axios from '../axios.js';
import {dateFormat} from '@/tool/transform.js';

// /新建资讯
export const createInfo = (data) =>{
  return Axios({
    url: `/api/admin/post`,
    method: 'post',
    // isFormData: true,
    data
  }).then(
    res => Promise.resolve(res),
    err => Promise.reject(err)
  );
}

// 获取资讯列表
export const getInfoList = (params) =>{
  return Axios({
    url: `/api/admin/post/postList`,
    method: 'get',
    params
  }).then(
      res => {
        res.data.data = res.data.data || [];
        res.data.data.map(item => {
          item.publishTime = dateFormat(item.publishTime);
        });
        return Promise.resolve(res.data);
      },
      err => Promise.reject(err)
    // res => Promise.resolve(res.data),
    // err => Promise.reject(err)
  );
}

// 修改排序
export const editSeq = (params) =>{
  return Axios({
    url: `/api/admin/post/seq/${params.id}`,
    method: 'put',
    params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}

// 修改排序
export const editStatus = (params) =>{
  return Axios({
    url: `/api/admin/post/status/${params.id}`,
    method: 'put',
    params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}

// 修改Banner展示
export const editBannerShow = (params) =>{
  return Axios({
    url: `/api/admin/post/bannerShow/${params.id}`,
    method: 'put',
    params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}


// 获取资讯详情
export const getDetail = (params) =>{
  return Axios({
    url: `/api/admin/post/${params.id}`,
    method: 'get',
    params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}

// 获取资讯详情
export const editDetail = (params) =>{
  return Axios({
    url: `/api/admin/post/${params.id}`,
    method: 'put',
    data: params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}

// 获取资讯详情
export const delInfo = (params) =>{
  return Axios({
    url: `/api/admin/post/${params.id}`,
    method: 'delete',
    data: params
  }).then(
    res => Promise.resolve(res.data),
    err => Promise.reject(err)
  );
}

 

// 在下面写就包含对象引入，以上属于可以解构的
export default {
  createInfo,
  getInfoList,
  editSeq,
  editStatus,
  editBannerShow,
  getDetail,
  editDetail,
  delInfo
}